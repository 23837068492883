module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-24730737-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"Hir3-Khaled","pagePaths":["/posts/private"],"partialMatching":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Khaled Shaaban - London based Senior Product Designer: UI / UX Specialist","short_name":"Khaled Shaaban","description":"A senior UX designer with over 12 years of experience delivering user-centred solutions which exceed business goals. London UK","start_url":"/","background_color":"#FFFFFF","theme_color":"#3d9bb5","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"lessBabel":true,"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/usr/src/app/www/node_modules/gatsby-remark-images","id":"b8994af5-3cda-5647-8054-9e797b795796","name":"gatsby-remark-images","version":"6.6.0","modulePath":"/usr/src/app/www/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":650,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent","wrapperStyle":"box-shadow: none;margin-top: 1rem; margin-bottom: 1rem;"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/usr/src/app/www","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent","wrapperStyle":"box-shadow: none;margin-top: 1rem; margin-bottom: 1rem;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
