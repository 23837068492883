/**
 * Write-only the password as cookie
 */
 import React, { useState } from 'react';
 import { setSessionPassword } from '../utils/utils';
 
 const styles = {
   wrapper: {
     height: '100vh',
     background: '#FFFFFF15',
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'center',
     alignItems: 'center'
   },
   input: {
     width: '100%',
     height: '48px',
     border: '1px solid #2d3748',
     paddingLeft: '16px',
     fontSize: '1.5rem',
   },
   button: {
     width: '100%',
     height: '48px',
     background: '#2d3748',
     color: '#fff',
     border: 'none',
     marginTop: '16px',
     cursor: 'pointer',
     fontSize: '1rem',
     fontWeight: '800',
   },
   buttonHover: {
     background: '#718096',
     color: '#fff'
   },
 };
 
 const PasswordProtect = () => {
   const [password, setPassword] = useState('');
   const [isButtonHovered, buttonHover] = useState(false);
 
   const onSubmit = event => {
     event.preventDefault();
     setSessionPassword(password);
     window.location.reload(); // eslint-disable-line
   };
 
   return (
     <div style={styles.wrapper}>
      <div style={{maxWidth: '320px'}}>
       <h1 style={{ color: '#000', fontSize:'1.875rem' }}>Content Protected</h1>
       <p style={{ color: '#2d3748', fontSize:'1.5rem'  }}>To view this content, please enter your password below.</p>
       <form onSubmit={onSubmit} style={{ width: '320px' }}>
       <label 
       for="password"
       style={{color:'#5f6c80', fontSize:'1.2rem' }}
       >Password
         <input
           autofocus="autofocus"
           name="password"
           type="password"
           value={password}
           onChange={event => setPassword(event.target.value)}
           style={styles.input}
         />
 </label>
         <button
           type="submit"
           style={{
             ...styles.button,
             ...(isButtonHovered ? styles.buttonHover : null)
           }}
           onMouseEnter={() => buttonHover(true)}
           onMouseLeave={() => buttonHover(false)}
         >
           View content
         </button>
       </form>
       </div>
     </div>
   );
 };
 
 export default PasswordProtect;
 